@font-face {
    font-family: "MuseoSansCyrl100Italic";
    src: url("../fonts/MuseoSansCyrl100Italic/MuseoSansCyrl100Italic.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl100Italic/MuseoSansCyrl100Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "MuseoSansCyrl300Italic";
    src: url("../fonts/MuseoSansCyrl300Italic/MuseoSansCyrl300Italic.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl300Italic/MuseoSansCyrl300Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "MuseoSansCyrl500Italic";
    src: url("../fonts/MuseoSansCyrl500Italic/MuseoSansCyrl500Italic.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl500Italic/MuseoSansCyrl500Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "MuseoSansCyrl700Italic";
    src: url("../fonts/MuseoSansCyrl700Italic/MuseoSansCyrl700Italic.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl700Italic/MuseoSansCyrl700Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "MuseoSansCyrl900Italic";
    src: url("../fonts/MuseoSansCyrl900Italic/MuseoSansCyrl900Italic.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl900Italic/MuseoSansCyrl900Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "MuseoSansCyrl100";
    src: url("../fonts/MuseoSansCyrl100/MuseoSansCyrl100.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl100/MuseoSansCyrl100.ttf") format("truetype");
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: "MuseoSansCyrl300";
    src: url("../fonts/MuseoSansCyrl300/MuseoSansCyrl300.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl300/MuseoSansCyrl300.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "MuseoSansCyrl500";
    src: url("../fonts/MuseoSansCyrl500/MuseoSansCyrl500.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl500/MuseoSansCyrl500.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "MuseoSansCyrl700";
    src: url("../fonts/MuseoSansCyrl700/MuseoSansCyrl700.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl700/MuseoSansCyrl700.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "MuseoSansCyrl900";
    src: url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.woff") format("woff");
    src: url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
